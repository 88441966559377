import ky from "ky";
import { API_CONFIG } from "@/config/env";

const api = ky.create({
  prefixUrl: API_CONFIG.BASE_URL,
  timeout: 30000,
  retry: {
    limit: 2,
    methods: ["get", "put", "head", "delete", "options", "trace"],
  },
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

export const getCouponCode = async (couponCode?: string) => {
  const searchParams = new URLSearchParams();
  if (couponCode) {
    searchParams.append("coupon", couponCode);
  }

  const response = await api
    .get("getCouponCodes", { searchParams })
    .json<{ code: string; amount: string; type: string }[]>();
  return response;
};
