/**
 * Environment configuration for the application
 */

const ENV = import.meta.env.VITE_ENV || "production";

if (!import.meta.env.VITE_API_BASE_URL) {
  throw new Error("VITE_API_BASE_URL is required in environment variables");
}

export const API_CONFIG = {
  /**
   * API base URL from environment variables
   */
  BASE_URL: import.meta.env.VITE_API_BASE_URL,

  /**
   * Environment name
   */
  ENV,

  /**
   * Whether the current environment is development
   */
  IS_DEVELOPMENT: ENV === "development",
} as const;
