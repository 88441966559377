import React from 'react';
import { useLocation } from 'react-router-dom';

const criticalResources = {
  '/': [
    { type: 'image', href: '/img/hero-image.webp', as: 'image' },
    { type: 'font', href: '/fonts/InterDisplay-Regular.ttf', as: 'font', crossOrigin: 'anonymous' },
    { type: 'font', href: '/fonts/InterDisplay-Medium.ttf', as: 'font', crossOrigin: 'anonymous' },
    { type: 'font', href: '/fonts/Euclid-Circular-A-Regular.ttf', as: 'font', crossOrigin: 'anonymous' },
    { type: 'font', href: '/fonts/Euclid-Circular-A-Medium.ttf', as: 'font', crossOrigin: 'anonymous' }
  ],
  '/tiktok-counter': [
    { type: 'image', href: '/tiktok-logo.svg', as: 'image' },
    { type: 'font', href: '/fonts/InterDisplay-Regular.ttf', as: 'font', crossOrigin: 'anonymous' },
    { type: 'font', href: '/fonts/InterDisplay-Medium.ttf', as: 'font', crossOrigin: 'anonymous' }
  ]
} as const;

export function PreloadResources() {
  const location = useLocation();
  const pathname = location.pathname;

  const resources = criticalResources[pathname as keyof typeof criticalResources] || [];

  return (
    <>
      {resources.map((resource, index) => {
        if (resource.type === 'image') {
          return (
            <link
              key={index}
              rel="preload"
              href={resource.href}
              as={resource.as}
            />
          );
        }
        if (resource.type === 'font') {
          return (
            <link
              key={index}
              rel="preload"
              href={resource.href}
              as={resource.as}
              crossOrigin={resource.crossOrigin}
              type="font/truetype"
            />
          );
        }
        return null;
      })}
    </>
  );
} 