import { Services } from "@/store/state";
import { usePostHog } from "posthog-js/react";
import { useMemo } from "react";

interface ServicePack {
  id: number;
  title: string;
  price: number;
  originalPrice: number;
  amount: number;
  discount?: number;
  promoted?: boolean;
}

const rawPriceList: Record<Services, ServicePack[]> = {
  followers: [
    {
      id: 15,
      title: "100 Followers",
      price: 2.99,
      originalPrice: 2.99,
      amount: 100,
    },
    {
      id: 16,
      title: "250 Followers",
      price: 6.49,
      originalPrice: 11.59,
      discount: 44,
      amount: 250,
    },
    {
      id: 17,
      title: "500 Followers",
      price: 7.99,
      originalPrice: 19.98,
      discount: 60,
      amount: 500,
    },
    {
      id: 3693,
      title: "1,000 Followers",
      price: 14.49,
      originalPrice: 36.23,
      discount: 60,
      amount: 1000,
      promoted: true,
    },
    {
      id: 19,
      title: "2,500 Followers",
      price: 29.99,
      originalPrice: 107.11,
      discount: 72,
      amount: 2500,
    },
    {
      id: 20,
      title: "5,000 Followers",
      price: 49.99,
      originalPrice: 208.29,
      discount: 76,
      amount: 5000,
    },
    {
      id: 210,
      title: "10,000 Followers",
      price: 89.99,
      originalPrice: 449.95,
      discount: 80,
      amount: 10000,
    },
    {
      id: 315,
      title: "20,000 Followers",
      price: 189.99,
      originalPrice: 1266.6,
      discount: 85,
      amount: 20000,
    },
    {
      id: 5159,
      title: "50,000 Followers",
      price: 449.99,
      originalPrice: 4499.9,
      discount: 90,
      amount: 50000,
    },
  ],
  likes: [
    {
      id: 29,
      title: "100 Likes",
      price: 2.49,
      originalPrice: 2.49,
      amount: 100,
    },
    {
      id: 30,
      title: "250 Likes",
      price: 5.49,
      originalPrice: 7.84,
      discount: 30,
      amount: 250,
    },
    {
      id: 31,
      title: "500 Likes",
      price: 6.49,
      originalPrice: 12.98,
      discount: 50,
      amount: 500,
    },
    {
      id: 32,
      title: "1,000 Likes",
      price: 9.99,
      originalPrice: 26.29,
      discount: 62,
      amount: 1000,
      promoted: true,
    },
    {
      id: 33,
      title: "2,500 Likes",
      price: 19.99,
      originalPrice: 66.63,
      discount: 70,
      amount: 2500,
    },
    {
      id: 34,
      title: "5,000 Likes",
      price: 35.99,
      originalPrice: 143.96,
      discount: 75,
      amount: 5000,
    },
    {
      id: 209,
      title: "10,000 Likes",
      price: 59.99,
      originalPrice: 260.83,
      discount: 77,
      amount: 10000,
    },
    {
      id: 3564,
      title: "20,000 Likes",
      price: 159.99,
      originalPrice: 799.95,
      discount: 80,
      amount: 20000,
    },
  ],
  views: [
    {
      id: 43,
      title: "500 Views",
      price: 1.99,
      originalPrice: 1.99,
      amount: 500,
    },
    {
      id: 44,
      title: "1,000 Views",
      price: 2.49,
      originalPrice: 3.0,
      discount: 17,
      amount: 1000,
    },
    {
      id: 45,
      title: "2,500 Views",
      price: 3.99,
      originalPrice: 7.53,
      discount: 47,
      amount: 2500,
    },
    {
      id: 46,
      title: "5,000 Views",
      price: 4.99,
      originalPrice: 15.12,
      discount: 67,
      amount: 5000,
    },
    {
      id: 47,
      title: "10,000 Views",
      price: 6.99,
      originalPrice: 27.96,
      discount: 75,
      amount: 10000,
      promoted: true,
    },
    {
      id: 48,
      title: "20,000 Views",
      price: 12.99,
      originalPrice: 76.41,
      discount: 83,
      amount: 20000,
    },
    {
      id: 208,
      title: "50,000 Views",
      price: 24.99,
      originalPrice: 192.23,
      discount: 87,
      amount: 50000,
    },
    {
      id: 2788,
      title: "100,000 Views",
      price: 69.99,
      originalPrice: 583.25,
      discount: 88,
      amount: 100000,
    },
    {
      id: 2790,
      title: "250,000 Views",
      price: 129.99,
      originalPrice: 1444.33,
      discount: 91,
      amount: 250000,
    },
    {
      id: 2791,
      title: "500,000 Views",
      price: 239.99,
      originalPrice: 2999.88,
      discount: 92,
      amount: 500000,
    },
  ],
  shares: [
    {
      id: 36,
      title: "100 Shares",
      price: 2.49,
      originalPrice: 2.49,
      amount: 100,
    },
    {
      id: 37,
      title: "250 Shares",
      price: 3.99,
      originalPrice: 6.23,
      discount: 36,
      amount: 250,
    },
    {
      id: 38,
      title: "500 Shares",
      price: 5.49,
      originalPrice: 13.73,
      discount: 60,
      amount: 500,
    },
    {
      id: 39,
      title: "1,000 Shares",
      price: 9.99,
      originalPrice: 24.98,
      discount: 60,
      amount: 1000,
      promoted: true,
    },
    {
      id: 40,
      title: "2,500 Shares",
      price: 19.99,
      originalPrice: 62.47,
      discount: 68,
      amount: 2500,
    },
    {
      id: 41,
      title: "5,000 Shares",
      price: 34.99,
      originalPrice: 124.96,
      discount: 72,
      amount: 5000,
    },
  ],
};

export function useFilteredPriceList() {
  const client = usePostHog();
  const shouldHideLowestPrice =
    client?.getFeatureFlag("hide-lowest-price-package") === "test";

  return useMemo(
    () => ({
      followers: shouldHideLowestPrice
        ? rawPriceList.followers.slice(1)
        : rawPriceList.followers,
      likes: shouldHideLowestPrice
        ? rawPriceList.likes.slice(1)
        : rawPriceList.likes,
      views: shouldHideLowestPrice
        ? rawPriceList.views.slice(1)
        : rawPriceList.views,
      shares: shouldHideLowestPrice
        ? rawPriceList.shares.slice(1)
        : rawPriceList.shares,
    }),
    [shouldHideLowestPrice]
  );
}

// Export raw price list for cases where we need the unfiltered version
export const priceList = rawPriceList;

export const policyLinks = [
  {
    title: "Terms",
    displayText: "Terms",
  },
  {
    title: "Privacy",
    displayText: "Privacy",
  },
  {
    title: "Refund",
    displayText: "Refund",
  },
];

export const policyContents = [
  {
    title: "Terms of Service and Use",
    key: "Terms",
    content: `
      <div class="space-y-4">
        <h2 class="text-body-xs-m !mb-1 mt-4">1. Terms</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">By accessing this website, available at https://www.famepeak.io, you agree to be bound by these Terms of Service and Use and agree that you are responsible for compliance with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials on this website are protected by copyright and trademark law.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">2. Use License</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Permission is granted to temporarily download one copy of the materials on Famepeak’s website for personal, non-commercial, transitory viewing only. This license is a grant, not a transfer of title, and under this license, you may not:</p>
        <ul class="list-disc pl-6 space-y-2 text-gray-400 text-body-2xs-r !mt-0">
          <li>Modify or copy the materials;</li>
          <li>Use the materials for any commercial purpose or for any public display;</li>
          <li>Attempt to reverse engineer any software contained on Famepeak's Website;</li>
          <li>Remove any copyright or other proprietary notations from the materials; or</li>
          <li>Transferring the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        <p class="text-gray-400 text-body-2xs-r !mt-4">Famepeak reserves the right to terminate this license if you violate any of these restrictions. Upon termination, your viewing rights will also end, and you must destroy any downloaded materials in your possession, whether in printed or electronic format.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">3. Disclaimer</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">All materials on Famepeak’s website are provided “as is.” Famepeak makes no warranties, expressed or implied, and hereby disclaims all other warranties. Further, Famepeak makes no representations regarding the accuracy or reliability of the materials on its website or any sites linked to this website.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">4. Limitations</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Famepeak and its suppliers will not be held liable for any damages arising from the use or inability to use the materials on Famepeak’s website, even if Famepeak or an authorized representative has been notified of the possibility of such damages. Some jurisdictions do not allow limitations on implied warranties or liability for incidental damages; therefore, these limitations may not apply to you.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">5. Revisions and Errata</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">The materials appearing on Famepeak’s website may include technical, typographical, or photographic errors. Famepeak does not guarantee that any materials on its website are accurate, complete, or current. Famepeak may change the materials on its website at any time without notice but does not commit to updating them.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">6. Links</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Famepeak has not reviewed all sites linked to its website and is not responsible for the contents of any linked site. The inclusion of any link does not imply endorsement by Famepeak. Use of any linked website is at the user’s own risk.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">7. Modifications to Terms</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Famepeak may revise these Terms of Service and Use for its website at any time without prior notice. By using this website, you agree to be bound by the then-current version of these Terms of Service and Use.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">8. Your Privacy</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Please review our Privacy Policy for details on how we handle your information.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">9. Governing Law</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Any claim related to Famepeak’s website shall be governed by the laws of the United Kingdom, without regard to its conflict of law provisions.</p>
      </div>
    `,
  },
  {
    title: "Privacy Policy",
    key: "Privacy",
    content: `
      <div class="space-y-4">
        <h2 class="text-body-xs-m !mb-1 mt-4">1. Introduction</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Your privacy is important to us. It is Famepeak's policy to respect your privacy and comply with applicable laws and regulations regarding any personal information we may collect about you, including through our website, https://famepeak.io, and any other sites we own and operate.</p>
        
        <p class="text-gray-400 text-body-2xs-r !mt-3">This policy is effective as of January 1, 2021, and was last updated on January 1, 2021.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">2. Information We Collect</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We collect information that you knowingly provide to us when using or participating in any of our services and promotions, as well as information automatically sent by your devices when accessing our products and services.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">3. Log Data</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">When you visit our website, our servers may automatically log standard data provided by your web browser, including your device's Internet Protocol (IP) address, browser type and version, pages visited, time and date of visit, time spent on each page, and other technical details. This data may not identify you personally by itself, but it may be possible to combine it with other information to personally identify individuals.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">4. Collection and Use of Information</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We may collect personal information from you when you:</p>
        <ul class="list-disc pl-6 space-y-2 text-gray-400 text-body-2xs-r !mt-0">
          <li>Use a mobile device or web browser to access our content</li>
          <li>Contact us via email, social media, or other similar technologies</li>
          <li>Mention us on social media</li>
        </ul>
        
        <p class="text-gray-400 text-body-2xs-r !mt-3">We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a way incompatible with these purposes.</p>
        
        <p class="text-gray-400 text-body-2xs-r !mt-3">Please note that we may combine information we collect about you with general information or research data from other trusted sources.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">5. Security of Your Personal Information</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We use commercially acceptable means to protect your personal information from loss, theft, unauthorized access, disclosure, copying, use, or modification. While we strive to protect your information, no method of electronic transmission or storage is 100% secure, and absolute data security cannot be guaranteed. We comply with applicable laws regarding any data breaches.</p>
        
        <p class="text-gray-400 text-body-2xs-r !mt-0">You are responsible for selecting a secure password and maintaining its confidentiality when using our services.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">6. How Long We Keep Your Personal Information</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We retain your personal information only as long as necessary for the purposes outlined in this privacy policy. When it is no longer required, we will delete or anonymize it. However, we may retain information for compliance with legal, accounting, or reporting obligations or for public interest, scientific, or historical research, or statistical purposes, if necessary.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">7. Children's Privacy</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Our products and services are not directed at children under 13, and we do not knowingly collect personal information from children under 13.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">8. Disclosure of Personal Information to Third Parties</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We may disclose personal information to:</p>
        <ul class="list-disc pl-6 space-y-2 text-gray-400 text-body-2xs-r !mt-0">
          <li>Our parent, subsidiary, or affiliated companies</li>
          <li>Third-party service providers, such as IT providers, data storage, hosting, advertising, or analytics platforms</li>
          <li>Our employees, contractors, and related entities</li>
          <li>Our agents or business partners</li>
          <li>Courts, tribunals, regulatory authorities, and law enforcement, as required by law</li>
        </ul>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">9. International Transfers of Personal Information</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">The personal information we collect may be stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities. These locations may not have the same data protection laws as your country. When we transfer your personal information to other countries, we will do so in accordance with applicable law and take steps to protect it in accordance with this privacy policy.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">10. Your Rights and Controlling Your Personal Information</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">You have the right to withhold personal information from us, though this may affect your experience of our website. We will not discriminate against you for exercising your rights over your personal information. If you provide us with personal information, you consent to our collection, use, and disclosure in accordance with this privacy policy.</p>
        
        <p class="text-gray-400 text-body-2xs-r !mt-3">If we receive your personal information from a third party, we will protect it as outlined in this policy. If you provide personal information about someone else, you represent that you have their consent.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">11. Use of Cookies</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We use "cookies" to collect information about you and your activity on our site. A cookie is a small piece of data stored on your device, accessed each time you visit, to help us understand how you use our site and serve content based on your preferences.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">12. Limits of Our Policy</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">Our website may link to external sites not operated by us. We are not responsible for the content and privacy practices of these sites.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">13. Changes to This Policy</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We may update this privacy policy to reflect changes in our business practices, legal requirements, or industry standards. Any changes will be posted on this page, and we will update the "effective date" accordingly. If required by law, we will seek your permission or give you the option to opt-in or opt-out, as applicable, for new uses of your personal information.</p>
        
      </div>
    `,
  },
  {
    title: "Refund Policy",
    key: "Refund",
    content: `
      <div class="space-y-4">
        <h2 class="text-body-xs-m !mb-1 mt-4">1. 10-Day Satisfaction Guarantee</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">We offer a 10-day satisfaction guarantee from the date of your initial purchase. If your order has not been delivered and is still queued, you may request a full refund within this 10-day period.</p>
        
        <p class="text-gray-400 text-body-2xs-r !mt-0">However, if your order has been delivered, the payment is non-refundable. This is because we are unable to retract followers, likes, views, or shares once they have been provided. If more than 10 days have passed since your purchase, we cannot offer a refund under any circumstances.</p>
        
        <p class="text-gray-400 text-body-2xs-r !mt-4">To process your refund, we require a receipt or proof of purchase. In certain situations, only partial refunds may be granted for services that are more than 10 days post-delivery. These cases will be assessed individually.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">2. TikTok™ Package Refund Policy</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">If you have purchased a TikTok package and over half of the allotted posts have already been serviced within the 10-day refund period, you will not be eligible for a refund. This policy is in place to prevent the use of free services with the intent to cancel after partial fulfillment.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">3. Refund Processing</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">If your refund request is approved and falls within the terms of our policy, we will process your refund. A credit will be automatically applied to your credit card or original payment method within 3–5 business days.</p>
        
        <h2 class="text-body-xs-m !mb-1 mt-4">4. Late or Missing Refunds</h2>
        <p class="text-gray-400 text-body-2xs-r !mt-0">If you haven't received your refund yet, please take the following steps:</p>
        <ul class="list-disc pl-6 space-y-2 text-gray-400 text-body-2xs-r !mt-0">
          <li>Check your bank account again.</li>
          <li>Contact your credit card company, as it may take some time for the refund to be officially posted.</li>
          <li>Contact your bank, as processing times can vary.</li>
        </ul>
        
        <p class="text-gray-400 text-body-2xs-r !mt-4">If you've completed these steps and still have not received your refund, please contact us at <a href="mailto:support@famepeak.io" class="text-purple-300">support@famepeak.io</a></p>
      </div>
    `,
  },
];
